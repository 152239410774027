import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import Link from "../UI/Link";
import * as cardStyles from "./product-card.module.css";

const cx = classNames.bind(cardStyles);

const ProductCard = ({slug, title, url, description}) => {
    return (
        <article
            className={cx({
                productCard: true,
            })}
        >
            <div>
                <p className={cx({productCard__kicker: true})}>{slug.toUpperCase()}</p>
                <h4 className={cx({productCard__title: true})}>{title}</h4>
                <p className={cx({productCard__description: true})}>{description}</p>
            </div>
            <div>
                <Link className={cx({productCard__link: true})} to={url}>
                    Подробнее
                </Link>
            </div>
        </article>
    );
};

ProductCard.defaultProps = {
    description: "",
};

ProductCard.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export default ProductCard;
