import {useCallback} from "react";
import * as React from "react";
import Features from "./Features";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as integrationsStyles from "./integrations.module.css";

const Integrations = ({integrations}) => {
    const getIntegrationDescription = useCallback(
        (integrationId) => {
            const integration = integrations.find(({id}) => integrationId === id);
            if (!integration) {
                return <div />;
            }

            return (
                <div>
                    <div className={integrationsStyles.integrations__media}>
                        <Media media={integration.media} />
                        <div>
                            <div className={integrationsStyles.integrations__gradient} />
                            <div className={integrationsStyles.integrations__gradient} />
                        </div>
                    </div>
                    <div className={integrationsStyles.integrations__content}>
                        <p className={integrationsStyles.integrations__description}>
                            <TemplateString template={integration.description} />
                        </p>
                    </div>
                </div>
            );
        },
        [integrations],
    );

    return (
        <Features
            id="integrations"
            title="Интеграции"
            items={integrations}
            getItemDescription={getIntegrationDescription}
        />
    );
};

export default Integrations;
