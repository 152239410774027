import {useCallback} from "react";
import * as React from "react";
import Features from "./Features";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as modulesStyles from "./modules.module.css";

const Modules = ({modules}) => {
    const getModuleDescription = useCallback(
        (moduleId) => {
            const module = modules.find(({id}) => moduleId === id);
            if (!module) {
                return <div />;
            }

            const {media, description, supportTerms, outsourceTerms, customerTerms} = module;
            const haveTerms = [supportTerms, outsourceTerms, customerTerms].some(Boolean);

            return (
                <div>
                    <div className={modulesStyles.modules__media}>
                        <Media media={media} />
                        <div>
                            <div className={modulesStyles.modules__gradient} />
                            <div className={modulesStyles.modules__gradient} />
                        </div>
                    </div>
                    <div className={modulesStyles.modules__content}>
                        <p className={modulesStyles.modules__description}>
                            <TemplateString template={description} />
                        </p>
                        {haveTerms && (
                            <div>
                                <h4 className={modulesStyles.modules__termsTitle}>Условия предоставления</h4>
                                {supportTerms && (
                                    <p
                                        className={`${modulesStyles.modules__term} ${modulesStyles.modules__term_support}`}
                                    >
                                        <span>Support:</span> {supportTerms}
                                    </p>
                                )}
                                {outsourceTerms && (
                                    <p
                                        className={`${modulesStyles.modules__term} ${modulesStyles.modules__term_outsource}`}
                                    >
                                        <span>Outsource:</span> {outsourceTerms}
                                    </p>
                                )}
                                {customerTerms && (
                                    <p
                                        className={`${modulesStyles.modules__term} ${modulesStyles.modules__term_customer}`}
                                    >
                                        <span>Customer:</span> {customerTerms}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [modules],
    );

    return <Features id="modules" title="Модули" items={modules} getItemDescription={getModuleDescription} />;
};

export default Modules;
