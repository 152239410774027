import {useMediaQuery} from "@react-hook/media-query";
import classNames from "classnames";
import PropTypes from "prop-types";
import {useCallback, useEffect, useState} from "react";
import * as React from "react";
import Section from "../Layout/Section";
import SectionTitle from "../Layout/Section/Title";
import * as featuresStyles from "./features.module.css";

const Features = ({id, title, items, getItemDescription}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    const [activeItemId, setActiveItemId] = useState(items[0]?.id);
    const [activeItemIds, setActiveItemIds] = useState([items[0]?.id]);

    const updateActive = useCallback(
        (itemId) => {
            if (!isMobile) {
                setActiveItemId(itemId);

                return;
            }

            setActiveItemIds((currentActiveIds) => {
                if (!currentActiveIds.includes(itemId)) {
                    return [...currentActiveIds, itemId];
                }

                return currentActiveIds.filter((item) => item !== itemId);
            });
        },
        [isMobile],
    );

    useEffect(() => {
        if (isMobile) {
            setActiveItemId(items[0]?.id);
        } else {
            setActiveItemIds([items[0]?.id]);
        }
    }, [isMobile, items]);

    return (
        <Section id={id}>
            <div className={featuresStyles.features}>
                <div>
                    <SectionTitle>{title}</SectionTitle>
                    <div className={featuresStyles.features__buttonList}>
                        {items.map(({id: itemId, title: itemTitle}) => {
                            return (
                                <div key={itemId} className={featuresStyles.features__buttonWrapper}>
                                    <button
                                        type="button"
                                        onClick={() => updateActive(itemId)}
                                        className={classNames({
                                            [featuresStyles.features__button]: true,
                                            [featuresStyles.features__button_active]: !isMobile
                                                ? activeItemId === itemId
                                                : activeItemIds.includes(itemId),
                                        })}
                                    >
                                        {itemTitle}
                                    </button>
                                    {isMobile && getItemDescription(itemId)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {!isMobile && <div>{getItemDescription(activeItemId)}</div>}
            </div>
        </Section>
    );
};

Features.defaultProps = {
    id: "",
    title: "",
};

Features.propTypes = {
    id: PropTypes.string,
    title: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, title: PropTypes.string})).isRequired,
    getItemDescription: PropTypes.func.isRequired,
};

export default Features;
