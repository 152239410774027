import {graphql} from "gatsby";
import * as React from "react";
import {CourseJsonLd} from "gatsby-plugin-next-seo";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import Hero from "../components/Tour/Hero";
import Products from "../components/Home/Products";
import Capabilities from "../components/Tour/Capabilities";
import Modules from "../components/Tour/Modules";
import Apps from "../components/Tour/Apps";
import Integrations from "../components/Tour/Integrations";
import FreeDemoForm from "../components/Form/FreeDemo";

const TourPage = ({data: {mainPageData, tourPage}, location}) => {
    const {
        hero,
        capabilities,
        apps,
        modules: {modules},
        integrations: {integrations},
        seo,
    } = tourPage;
    const {our_products: products} = mainPageData;

    return (
        <Layout>
            <Seo seo={seo} />
            <CourseJsonLd
                name={seo.title}
                providerName="ITSM 365"
                providerUrl={location.origin}
                description={seo.description}
                overrides={{
                    "@type": "WebPage",
                }}
            />
            <Hero data={hero} />
            <Capabilities sectionTitle="Возможности" {...capabilities} />
            <Modules modules={modules} />
            <Apps {...apps} />
            <Integrations integrations={integrations} />
            <Products {...products} />
            <FreeDemoForm />
        </Layout>
    );
};

export default TourPage;

export const pageQuery = graphql`
    query {
        tourPage: strapiTour {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            hero {
                title
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
                variant
            }
            capabilities {
                description
                capabilityItems {
                    id
                    title
                    description
                    icon {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
            modules {
                modules {
                    id
                    title
                    media {
                        youtubeVideoURL
                        content {
                            mime
                            width
                            height
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                    description
                    supportTerms
                    outsourceTerms
                    customerTerms
                }
            }
            apps {
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
                description
                appStoreURL
                googlePlayURL
            }
            integrations {
                integrations {
                    id
                    title
                    description
                    media {
                        youtubeVideoURL
                        content {
                            mime
                            width
                            height
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
        mainPageData: strapiMain {
            our_products {
                title
                description
                products {
                    title: shortTitle
                    short_description
                    type
                    slug
                }
            }
        }
    }
`;
