import * as React from "react";
import AppStore from "../../assets/svg/stores/appStore.svg";
import GooglePlay from "../../assets/svg/stores/googlePlay.svg";
import ContentBlock from "../Layout/ContentBlock";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as appsStyles from "./apps.module.css";

const Apps = ({description, appStoreURL, googlePlayURL, media}) => {
    const leftContent = (
        <div className={appsStyles.apps__mobileApps}>
            {appStoreURL && (
                <a href={appStoreURL} target="_blank" rel="noreferrer" className={appsStyles.apps__mobileAppsLink}>
                    <AppStore />
                </a>
            )}
            {googlePlayURL && (
                <a href={googlePlayURL} target="_blank" rel="noreferrer" className={appsStyles.apps__mobileAppsLink}>
                    <GooglePlay />
                </a>
            )}
        </div>
    );

    const rightContent = (
        <div className={appsStyles.apps__media}>
            <Media media={media} />
            <div>
                <div className={appsStyles.apps__gradient} />
                <div className={appsStyles.apps__gradient} />
            </div>
        </div>
    );

    return (
        <ContentBlock
            id="apps"
            reversed
            title="Мобильное приложение"
            desc={<TemplateString template={description} />}
            leftContent={leftContent}
            rightContent={rightContent}
            alignTop
            alignLeft
            className={appsStyles.apps}
        />
    );
};

export default Apps;
