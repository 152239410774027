// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--9BxnZ";
export var hero__title = "hero-module--hero__title--56uWq";
export var hero__media = "hero-module--hero__media--3nXSa";
export var hero__gradient = "hero-module--hero__gradient--eSIiZ";
export var hero__gradient_laptop = "hero-module--hero__gradient_laptop--s112z";
export var hero__gradient_tablet = "hero-module--hero__gradient_tablet--a2yXp";
export var hero__gradient_mobile = "hero-module--hero__gradient_mobile--WuPfU";
export var hero_textLeft = "hero-module--hero_text-left--G00Sa";
export var hero__button = "hero-module--hero__button--F3wVQ";
export var hero__gradient_desktop = "hero-module--hero__gradient_desktop--wYJm+";