import {useMediaQuery} from "@react-hook/media-query";
import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import ProductCard from "../Product/Card";
import Slider from "../Common/Slider";
import SliderPagination from "../Common/Slider/Pagination";
import * as productsStyles from "./products.module.css";

const Products = ({title, description, products}) => {
    const showPagination = useMediaQuery("(max-width: 1024px)");

    return (
        <Section title={title} desc={description}>
            <Slider
                sliderClassName={productsStyles.products__slider}
                containerClassName={productsStyles.products__sliderContainer}
                pagination={
                    showPagination && <SliderPagination className={productsStyles.products__sliderPagination} />
                }
            >
                {products.map(({slug, title: cardTitle, short_description: cardDescription}, idx) => (
                    <ProductCard
                        slug={slug}
                        title={cardTitle}
                        description={cardDescription}
                        url={`/product/${slug}`}
                        key={idx}
                    />
                ))}
            </Slider>
        </Section>
    );
};

Products.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.exact({
            type: PropTypes.oneOf(["support", "outsource", "customer"]).isRequired,
            title: PropTypes.string.isRequired,
            short_description: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default Products;
