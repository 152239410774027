import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as heroStyles from "./hero.module.css";
import Container from "../Layout/Container";

const Hero = ({data}) => {
    const {title, media, variant = "text_above"} = data;

    return (
        <Container className={`${heroStyles.hero}${variant === "text_left" ? ` ${heroStyles.hero_textLeft}` : ""}`}>
            <h1 className={heroStyles.hero__title}>
                <TemplateString template={title} />
            </h1>
            {media && (
                <div className={heroStyles.hero__media}>
                    <Media media={media} big={variant !== "text_left"} imageProps={{loading: "eager"}} />
                </div>
            )}
            <div>
                <StaticImage
                    src="../../assets/images/tour/hero/desktop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_desktop}`}
                />
                <StaticImage
                    src="../../assets/images/tour/hero/laptop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_laptop}`}
                />
                <StaticImage
                    src="../../assets/images/tour/hero/tablet.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/tour/hero/mobile.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    loading="eager"
                    className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_mobile}`}
                />
            </div>
        </Container>
    );
};

Hero.propTypes = {
    data: PropTypes.shape({
        variant: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        media: PropTypes.exact({
            youtubeVideoURL: PropTypes.string,
            content: PropTypes.shape({
                mime: PropTypes.string.isRequired,
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }),
        }).isRequired,
    }).isRequired,
};

export default Hero;
